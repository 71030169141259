/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here node_modules/bootstrap/scss/_variables.scss
 */
$enable-flex : true;

@import 'variables';

@import '../../../node_modules/bootstrap/scss/bootstrap';

$fa-font-path : '../../../node_modules/font-awesome/fonts';

body {
    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

md-toolbar {
  box-shadow: 0 0 5px rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(0,0,0,0.070);
}

md-spinner {
  circle {
    stroke: $blue !important;
  }
}

/**
* Redefines button style
*/

*:focus {outline:0;}
button:focus {outline:0;}

.md-primary[md-raised-button] {
    background-color: $blue !important;
    border-radius: 2px;
}

//.md-accent[md-raised-button] {
//    background-color: $accent !important;
//    border-radius: 2px;
//    color: #fff;
//}

/**
* Default style
*/

.margin-bottom {
    margin-bottom: 15px;
}

md-input{
  overflow: visible !important;
}

.md-input-element {
  background: transparent !important;
}

/**
* Payment method
*/

.payment-method {

    .md-radio-label {
        border: 1px solid #e5e5e5;
        border-radius: 2px;
        width: 244px;
        text-align: center;
        padding: 15px 5px;
        margin-right: 15px;
        transition: all 0.3s ease;

        &:hover, &:focus {
            background: #fafafa;
            cursor: pointer;
        }

        .md-radio-label-content {
            float: none;
            padding-left: 0;
            margin: auto;
        }

        .md-radio-container {
            display: none;
        }
    }

    .md-radio-checked {
        .md-radio-label {
            border: 1px solid $blue;
        }
    }

    .method-label {
        margin-top: 5px;
    }
}

/* Redefine colors for admin components */

.admin-components {
  .md-radio-checked .md-radio-outer-circle {
    border-color: #78909C;
  }

  .md-radio-inner-circle {
    background: #78909C;
  }

  .md-ink-ripple {
    background: #78909C;
  }

  .placeholder-up .md-input-placeholder {
    transform: translateY(-110%) scale(0.75) !important;
  }

  .placeholder-up .md-input-placeholder.md-focused {
    transform: translateY(-110%) scale(0.75) !important;
  }

  .md-input-placeholder.md-focused {
    color: #78909C!important;
  }

  .md-input-underline .md-input-ripple {
    background: #78909C !important;
  }

  .md-checkbox-background {
    background: #78909C;
  }

  .md-checkbox-layout label {
    margin-top: 9px;
    margin-right: 5px !important;
  }
}
